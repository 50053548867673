import React, { useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import colors from 'themes/colors';
import helpers from 'themes/helpers';
import NameLogo from 'components/NameLogo';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { mediaMax, mediaMin } from 'themes/media';
import { LogEventFns } from 'analytics/ga';

import { ReactComponent as SvgSymbol } from './additor-symbol.svg';
import { ReactComponent as SvgClose } from './close.svg';
import { ReactComponent as SvgMobileGNB } from './gnb.svg';

const StyledHeader = styled.div`
  height: 80px;
  padding: 0 20px;
  ${mediaMin.xs`
    padding: 0 40px;
  `};
  ${mediaMin.lg`
    padding: 0 80px;
  `};
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${colors.white};
  z-index: 100;
  transition: all 0.15s ease;

  & > div {
    max-width: 460px;
    ${mediaMin.xs`
      max-width: 1040px; 
    `};
    ${mediaMin.lg`
      max-width: 1120px;
    `};
    ${mediaMin.xlg`
      max-width: 1460px;
    `};
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

// eslint-disable-next-line no-unused-vars
const Link = styled(({ hovered, highlight, semiHighlight, ...props }) => (
  <RouterLink {...props} />
))`
  font-family: ObjectSans, sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 16px;
  line-height: 2.25;
  ${helpers.transition('opacity', 'background-color')};
  opacity: ${({ hovered }) => (hovered ? '1' : '.45')};
  height: 36px;
  border-radius: 18px;
  padding: ${({ highlight }) => (highlight ? '0 20px' : '0 40px 0 0')};
  background-color: ${({ highlight }) =>
    highlight ? colors.brand500 : colors.transparent};
  display: ${({ highlight }) => (highlight ? 'block' : 'inline-flex')};
  color: ${({ highlight, semiHighlight }) =>
    semiHighlight
      ? colors.brand500
      : highlight
      ? colors.white
      : colors.gray800};

  ${mediaMax.sm`
    padding: ${({ highlight }) => (highlight ? '0 20px' : '0 30px 20px 0')};
    height: ${({ highlight }) => (highlight ? '36px' : '66px')};
    display: 'inline-flex';
  `};

  &:hover {
    text-decoration: none;
    background-color: ${({ highlight }) =>
      highlight ? colors.brand700 : colors.transparent};
  }
`;

const LinkWrapper = styled.div`
  ${mediaMax.sm`
    display: flex;
  `};
`;

const Links = styled.div`
  display: flex;
  position: relative;
  font-family: ObjectSans, sans-serif;
  white-space: nowrap;
  font-size: 16px;
  line-height: 2.25;

  ${mediaMax.sm`
    display: ${({ visibleOnMobile }) => (visibleOnMobile ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 33px 36px;
    background-color: ${colors.white};
  `}
`;

const MobileOnly = styled.div`
  display: none;
  align-items: center;
  font-family: ObjectSans, sans-serif;
  white-space: nowrap;
  font-size: 16px;
  line-height: 2.25;
  ${mediaMax.sm`
    display: flex;
  `}
`;

const SignUpButton = styled(RouterLink)`
  ${helpers.centerFlex};
  white-space: nowrap;
  color: ${colors.white};
  padding: 0 24px;
  height: 36px;
  border-radius: 18px;
  background-color: ${colors.brand500};
  ${helpers.transition('background-color')};
  &:hover {
    text-decoration: none;
    background-color: ${colors.brand700};
  }
`;

const MobileGNBIcon = styled(SvgMobileGNB)`
  color: ${colors.brand500};
  width: 24px;
  height: 24px;
  margin-left: 20px;
  cursor: pointer;
  ${helpers.transition('background-color')};
  border-radius: 4px;
  &:hover {
    background-color: ${colors.gray80};
  }
`;

const CloseIcon = styled(SvgClose)`
  color: ${colors.gray700};
  display: none;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 38px;
  right: 30px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.gray80};
  }

  ${mediaMax.sm`
    display: block; 
  `}
`;
const Symbol = styled(SvgSymbol)`
  color: ${colors.brand500};
  width: 40px;
  height: 40px;
  margin-bottom: 30px;
  display: none;
  ${mediaMax.sm`
    display: block;
  `};
`;

function Header() {
  const { pathname } = useLocation();
  const [hoveredLink, setHoveredLink] = useState('');
  const [mobileGNBVisible, setMobileGNBVisible] = useState(false);
  const handleClickMobileGNBIcon = useCallback(() => {
    setMobileGNBVisible(true);
  }, []);
  const handleClickCloseMobileGNBIcon = useCallback(() => {
    setMobileGNBVisible(false);
  }, []);
  const handleMouseEnter = useCallback(e => {
    setHoveredLink(e.target.pathname);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHoveredLink('');
  }, []);

  const handleLogoClick = () => {
    if (pathname === '/home' || pathname === '/') {
      document.body.scrollTop = 0;
    }
    LogEventFns.header.clickAdditorButton();
  };

  return (
    <StyledHeader>
      <div>
        <RouterLink onClick={handleLogoClick} to="/home">
          <NameLogo />
        </RouterLink>
        <Links visibleOnMobile={mobileGNBVisible}>
          <CloseIcon onClick={handleClickCloseMobileGNBIcon} />
          <RouterLink to="/home" onClick={handleClickCloseMobileGNBIcon}>
            <Symbol />
          </RouterLink>
          <LinkWrapper>
            <Link
              to="/product"
              onClick={() => {
                LogEventFns.header.clickProductButton();
                setMobileGNBVisible(false);
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              hovered={!hoveredLink || hoveredLink === '/product'}
            >
              Product
            </Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link
              to="/slack-integration"
              onClick={() => {
                LogEventFns.header.clickIntegrationButton();
                setMobileGNBVisible(false);
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              hovered={!hoveredLink || hoveredLink === '/slack-integration'}
            >
              Integration
            </Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link
              to="/pricing"
              onClick={() => {
                LogEventFns.header.clickPricingButton();
                setMobileGNBVisible(false);
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              hovered={!hoveredLink || hoveredLink === '/pricing'}
            >
              Pricing
            </Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link
              to="/about"
              onClick={() => {
                LogEventFns.header.clickAboutUsButton();
                setMobileGNBVisible(false);
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              hovered={!hoveredLink || hoveredLink === '/about'}
            >
              About us
            </Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link
              semiHighlight
              to="/sign-in"
              onClick={() => {
                LogEventFns.header.clickSignInButton();
                setMobileGNBVisible(false);
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              hovered={!hoveredLink || hoveredLink === '/sign-in'}
              color={colors.brand500}
            >
              Sign in
            </Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link
              highlight
              to="/sign-up"
              onClick={() => {
                LogEventFns.header.clickSignUpButton();
                setMobileGNBVisible(false);
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              hovered={!hoveredLink || hoveredLink === '/sign-up'}
            >
              Sign up
            </Link>
          </LinkWrapper>
        </Links>
        <MobileOnly>
          <SignUpButton
            onClick={LogEventFns.header.clickSignUpButton}
            to="/sign-up"
          >
            Sign up
          </SignUpButton>
          <MobileGNBIcon onClick={handleClickMobileGNBIcon} />
        </MobileOnly>
      </div>
    </StyledHeader>
  );
}

export default Header;
