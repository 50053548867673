import React, { useCallback, useEffect, useState } from 'react';
import { Route } from 'react-router';
import _ from 'lodash';
import { useLocation } from 'react-router';

import { COOKIE_NAMES, getCookie } from 'utils/cookie';
import ScrollToTop from 'utils/scrollToTop';

import { ChromeExtensionSignIn, Root as OldRoot } from '../containers';
import Slack from '../containers/Slack';
import Main from './Contents/Main';
import Product from './Contents/Product';
import Pricing from './Contents/Pricing';
import About from './Contents/About';
import Footer from './Footer';
import Header from './Header';

const UsingOldRootComponentsPath = ['/sign-up', '/sign-in', '/mail-sent'];

function Root() {
  const location = useLocation();
  const [readyToRender, setReadyToRender] = useState(false);

  const redirectToLoggedInWorkspace = useCallback(() => {
    setReadyToRender(false);
    const workspaceEids = JSON.parse(
      getCookie(COOKIE_NAMES.WORKSPACE_EIDS) || '[]',
    );
    const latestWorkspaceEid = getCookie(COOKIE_NAMES.LATEST_WORKSPACE);
    const { location } = window;
    if (
      workspaceEids.length > 0 &&
      location.hostname !== 'localhost' &&
      location.pathname === '/'
    ) {
      if (latestWorkspaceEid) {
        location.href = `https://${latestWorkspaceEid}.${location.hostname}`;
      } else {
        location.href = `https://${workspaceEids[0]}.${location.hostname}`;
      }
    } else {
      setReadyToRender(true);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      redirectToLoggedInWorkspace();
    }
  }, [location]);

  useEffect(() => {
    redirectToLoggedInWorkspace();
  }, []);

  if (_.startsWith(location.pathname, '/chrome-extension-sign-in')) {
    return <ChromeExtensionSignIn />;
  }

  if (
    _.some(UsingOldRootComponentsPath, path =>
      _.startsWith(location.pathname, path),
    )
  ) {
    return <OldRoot location={location} />;
  }

  if (!readyToRender) {
    return null;
  }

  return (
    <>
      <ScrollToTop />
      <Header />
      <Route path="/" exact>
        <Main />
      </Route>
      <Route path="/home">
        <Main />
      </Route>
      <Route path="/product">
        <Product />
      </Route>
      <Route path="/pricing">
        <Pricing />
      </Route>
      <Route path="/slack-integration">
        <Slack />
      </Route>
      <Route path="/about">
        <About />
      </Route>
      <Footer />
    </>
  );
}

Root.propTypes = {};

export default Root;
