import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import colors from 'themes/colors';
import { mediaMin } from 'themes/media';
import NameLogo from 'components/NameLogo';
import {
  FacebookLogo,
  LinkedInLogo,
  ProductHuntLogo,
  TwitterLogo,
} from 'resources';
import { LogEventFns } from 'analytics/ga';

import { ReactComponent as SvgAdditorSymbol } from './additor-symbol.svg';

const LogoWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 18px;
`;

const Symbol = styled(SvgAdditorSymbol)`
  height: 56px;
  width: 56px;
  color: ${colors.darkairbrand};
`;

const Wrapper = styled.div`
  width: 100%;
  font-family: 'system-ui', Open Sans, Apple SD Gothic Neo, Noto Sans KR,
    sans-serif;
  padding: 0 16px;
  margin-bottom: 64px;
  ${mediaMin.xs`
    padding: 0 60px;
  `};
  ${mediaMin.sm`
    padding: 0 80px;
  `};
  ${mediaMin.md`
    padding: 0 100px;
  `};
  min-height: 550px;
  color: ${colors.darkairbrand};
`;

const FooterContent = styled.div`
  max-width: 460px;
  margin: 0 auto;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  ${mediaMin.xs`
    max-width: 1000px; 
  `};

  ${mediaMin.md`
    flex-direction: initial;
  `}

  ${mediaMin.xlg`
    max-width: 1200px;
  `};
`;

const AdditorDescription = styled.div`
  flex: 1;
  min-width: 220px;
  font-size: 14px;
  margin-top: 60px;

  p {
    margin: 0 0 8px;
    white-space: pre-wrap;
  }
`;

const Navigation = styled.div`
  flex: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto;

  ${mediaMin.sm`
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 4%;
  `}

  ${mediaMin.md`
    grid-gap: 10px;
  `}

  ${mediaMin.lg`
    grid-gap: 60px;
  `}

  ${mediaMin.xlg`
    grid-gap: 100px;
  `}
`;

const SocialLogo = styled.div`
  margin-top: 14px;
  margin-bottom: 40px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
`;

const NavLinks = styled.div`
  min-width: calc(50% - 40px);
  margin: 60px 0 0;
  display: flex;
  flex-direction: column;

  ${mediaMin.md`
    min-width: 128px;
  `}
`;

const Link = styled(RouterLink)`
  color: ${colors.darkairbrand};
  font-size: 15px;
  display: flex;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
`;

const PlainTitle = styled.div`
  color: ${colors.darkairbrand};
  white-space: nowrap;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 14px;
`;

const LinkTitle = styled(Link)`
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 14px;

  &:hover {
    text-decoration: none;
  }
`;

const ContentLink = styled(Link)`
  color: ${colors.darkairbrand};
  margin-top: 18px;

  &:hover {
    text-decoration: none;
  }
`;

const ExternalLink = styled.a`
  color: ${colors.darkairbrand};
  font-size: 15px;
  display: flex;
  white-space: nowrap;
  margin-top: 18px;

  &:hover {
    text-decoration: none;
  }
`;

const LinkButton = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    right: -6px;
    bottom: -2px;
    left: -6px;
    border-radius: 3px;
  }

  &:hover:before {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding: 0 8px;

  border-radius: 10px;
  background-color: ${colors.brand70};
  color: ${colors.brand500};
  font-size: 10px;
  font-weight: 500;
  pointer-events: none;
`;

function Footer() {
  const history = useHistory();
  useEffect(() => {
    const { hash } = history.location;
    if (!hash) {
      return;
    }
    const id = hash.substr(1);
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    element.scrollIntoView({ behavior: 'auto' });
  }, [history.location.hash]);

  const handleClickSocialItem = itemTitle => {
    switch (itemTitle) {
      case 'facebook':
        LogEventFns.footer.clickFacebookButton();
        break;
      case 'twitter':
        LogEventFns.footer.clickTwitterButton();
        break;
      case 'linkedin':
        LogEventFns.footer.clickLinkedInButton();
        break;
      case 'producthunt':
        LogEventFns.footer.clickProductHuntButton();
        break;
    }
  };

  const socialItems = [
    ['facebook', 'https://www.facebook.com/additor.io', FacebookLogo],
    ['twitter', 'https://twitter.com/additor_io', TwitterLogo],
    ['linkedin', 'https://www.linkedin.com/company/additor/', LinkedInLogo],
    [
      'producthunt',
      'https://www.producthunt.com/posts/additor-beta',
      ProductHuntLogo,
    ],
  ];
  return (
    <Wrapper>
      <FooterContent>
        <AdditorDescription>
          <LogoWrapper>
            <Symbol />
            <NameLogo type="white" />
          </LogoWrapper>
          <p>© Copyright 2021 Additor Inc.</p>
          <p>All rights reserved.</p>
        </AdditorDescription>
        <Navigation>
          <NavLinks>
            <PlainTitle>Links</PlainTitle>
            <ExternalLink
              href="https://air.additor.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkButton>Additor Air</LinkButton>
              <Chip>COVID</Chip>
            </ExternalLink>
            <ExternalLink
              href="https://apps.apple.com/app/additor/id1442745168"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkButton>iOS App</LinkButton>
            </ExternalLink>
            <ExternalLink
              href="https://chrome.google.com/webstore/detail/additor-highlight-organiz/hfllajanfnlimffhkjbondolipoimcgn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkButton>Web Clipper</LinkButton>
            </ExternalLink>
            <ContentLink to="/sign-in">
              <LinkButton>Sign in</LinkButton>
            </ContentLink>
            <ContentLink to="/sign-up">
              <LinkButton>Sign up</LinkButton>
            </ContentLink>
          </NavLinks>
          <NavLinks>
            <LinkTitle to="/product">
              <LinkButton>Product</LinkButton>
            </LinkTitle>
            <ContentLink to="/slack-integration">
              <LinkButton>Integration</LinkButton>
            </ContentLink>
            <ContentLink to="/pricing">
              <LinkButton>Pricing</LinkButton>
            </ContentLink>
            <ContentLink to="/product#video-feedback">
              <LinkButton>Video Feedback</LinkButton>
            </ContentLink>
            <ContentLink to="/product#track-changes">
              <LinkButton>Track changes</LinkButton>
            </ContentLink>
            <ContentLink to="/product#knowledge-library">
              <LinkButton>Knowledge library</LinkButton>
            </ContentLink>
            <ContentLink to="/product#note-editor">
              <LinkButton>Note editor</LinkButton>
            </ContentLink>
            <ContentLink to="/product#bi-directional-link">
              <LinkButton>Bi-directional link</LinkButton>
            </ContentLink>
            <ContentLink to="/product#content-management">
              <LinkButton>Content management</LinkButton>
            </ContentLink>
            <ContentLink to="/product#setting-permission">
              <LinkButton>Setting permission</LinkButton>
            </ContentLink>
            <ContentLink to="/product#external-collaboration">
              <LinkButton>External collaboration</LinkButton>
            </ContentLink>
          </NavLinks>
          <NavLinks>
            <PlainTitle>Company</PlainTitle>
            <ContentLink to="/about">
              <LinkButton>About</LinkButton>
            </ContentLink>
            <ExternalLink
              href="/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkButton>Terms of use</LinkButton>
            </ExternalLink>
            <ExternalLink
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkButton>Privacy policy</LinkButton>
            </ExternalLink>
          </NavLinks>
          <NavLinks>
            <PlainTitle>Follow us</PlainTitle>
            <SocialLogo>
              {socialItems.map(item => {
                const LogoComponent = item[2];
                return (
                  <a
                    href={item[1]}
                    title={item[0]}
                    key={item[0]}
                    onClick={() => handleClickSocialItem(item[0])}
                  >
                    <LogoComponent color={colors.darkairbrand} />
                  </a>
                );
              })}
            </SocialLogo>
          </NavLinks>
        </Navigation>
      </FooterContent>
    </Wrapper>
  );
}

export default Footer;
